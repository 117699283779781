@charset "utf-8";

// Agency brand colors
$primary:#bdbd95;
$secondary:#c6be76;
$secondary-hover: lighten($secondary, 10%);$media-color-one:#49759a;$media-color-two:#744d9f;$media-color-three:#00838f;$media-color-four:#00838f;$media-color-five:#00838f;$gap: 32px !default;
$desktop: 960px + (2 * $gap) !default;

// Mixins

@mixin touch() {
	@media screen and (max-width: $desktop - 1px){
    	@content
	}
}

@mixin desktop() {
	@media screen and (min-width: $desktop){
    	@content
	}
}

// Background
.bg{
  &-primary{
    background-color: $primary !important;
  }
  &-secondary {
    background-color: $secondary !important;
  }
  &-media-color {&-1{
        background-color: $media-color-one;
        &:hover {
          background-color: darken($media-color-one, 10%);
        }
      }&-2{
        background-color: $media-color-two;
        &:hover {
          background-color: darken($media-color-two, 10%);
        }
      }&-3{
        background-color: $media-color-three;
        &:hover {
          background-color: darken($media-color-three, 10%);
        }
      }&-4{
        background-color: $media-color-four;
        &:hover {
          background-color: darken($media-color-four, 10%);
        }
      }&-5{
        background-color: $media-color-five;
        &:hover {
          background-color: darken($media-color-five, 10%);
        }
      }}
  &-1{
    //background-color: $primary;
    background: url('https://c.stocksy.com/a/YY3500/z0/1205314.jpg') no-repeat left 50%;
    -webkit-background-size: cover;
    background-size: cover;
  }
  &-2{
    //background-color: $info;
    background: url('https://c.stocksy.com/a/FY3500/z0/1205295.jpg') no-repeat left 50%;
    -webkit-background-size: cover;
    background-size: cover;
  }
  &-3{
    //background-color: $warning;
    background: url('https://c.stocksy.com/a/L1o500/z0/1383923.jpg') no-repeat left 50%;
    -webkit-background-size: cover;
    background-size: cover;
  }
  &-4{
    //background-color: $danger;
    background: url('https://c.stocksy.com/a/Z22000/z0/7847.jpg') no-repeat left 50%;
    -webkit-background-size: cover;
    background-size: cover;
  }
  &-gradient{
    background: #b41e8e;
    background: -moz-linear-gradient(45deg, #b41e8e 0%, #5c3f9e 50%, #0061af 100%);
    background: -webkit-linear-gradient(45deg, #b41e8e 0%,#5c3f9e 50%,#0061af 100%);
    background: linear-gradient(45deg, #b41e8e 0%,#5c3f9e 50%,#0061af 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b41e8e', endColorstr='#0061af',GradientType=1 );
  }
  &-singpass{
    background: #be2e7d; /* Old browsers */
    background: -moz-linear-gradient(45deg, #be2e7d 0%, #d64e54 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #be2e7d 0%,#d64e54 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #be2e7d 0%,#d64e54 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#be2e7d', endColorstr='#d64e54',GradientType=1 ); /*IE6-9 fallback on horizontal gradient*/
  }
  &-beeline{
    background: #009d62; /* Old browsers */
    background: -moz-linear-gradient(45deg, #009d62 0%, #0094d3 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #009d62 0%,#0094d3 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #009d62 0%,#0094d3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#009d62', endColorstr='#0094d3',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

  }
  &-parkingsg{
    background: #0164b6; /* Old browsers */
    background: -moz-linear-gradient(45deg, #0164b6 0%, #04a4e3 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #0164b6 0%,#04a4e3 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #0164b6 0%,#04a4e3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0164b6', endColorstr='#04a4e3',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  }
}

// Color definitions
.is {
	&-primary {
		background-color: $primary !important;
	}
	&-secondary {
		background-color: $secondary !important;
	}
}

// Text color
.has-text-primary {
	color: $primary !important;
}
.has-text-secondary {
	color: $secondary !important;
}

// Menu/Left-nav
.bp-menu-list a.is-active {
	color: $secondary !important;
	border-bottom: 2px solid $secondary !important;
	&:hover {
		border-bottom: 2px solid $secondary !important;
	}
}
.bp-menu-list a:hover {
	color: $secondary !important;
	border-bottom: 1px solid $secondary !important;
}

// Button

.bp-button.is-text span {
  &:hover, &:focus {
    color: $secondary;
  }
}
.bp-button:hover {
  color: $secondary;
}

// Secondary button
.bp-sec-button {
	color: $secondary;
	& span {
		border-bottom: 2px solid $secondary !important;
	}
	&:hover {
		& span {
	    	border-bottom: 2px solid $secondary-hover !important;
	    	color: $secondary-hover !important;
	  	}
	  	& i {
	    	color: $secondary-hover !important;
	  	}
	}
}

// Bottom navigation bar
.bottom-navigation {
	a {
		background-color: $secondary;
	    &:hover{
	      background-color: darken($secondary,10%) !important;
	    }
	}
}

.bp-section.bp-section-pagetitle {
  background-color: $primary !important;
}

// Pagination
.pagination {
	span {
		&:hover {
            border-color: $secondary;
            color: $secondary;
		}
        &.selected-page:hover {
            background-color: $secondary;
            border-color: $secondary;
        }
	}
    .selected-page {
        background-color: $secondary;
        border-color: $secondary;
    }
}


// Homepage
#key-highlights{
	background-color: $primary;
	.col {
		border-left:1px solid darken($primary,10%);
		@include touch(){
			border-top:1px solid darken($primary,10%);
		}
		&:hover{
			background-color: darken($primary,10%);
		}
	}
}

// Navbar
.navbar{
  &-start{
    .navbar-item{
      a{
        &.active{
          @include touch(){
            border-bottom:2px solid $secondary;
          }
        }
      }
    }
  }
  .navbar-link:hover:after {
    border-bottom: 1px solid $secondary;
    border-left: 1px solid $secondary;
  }

  .navbar-item{
    .sub-link.is-active{
      color:$secondary;
    }

    .selector{
      &.active{
        background-color:$secondary;
      }
    }
    &.active{
      @include desktop(){
        border-bottom:1px solid $secondary;
      }
    }
  }
.navbar-item:hover {
  a {
    color:$secondary;
  }
  .selector {
      background-color:$secondary;
    }
  }
}

// Post-content
.content {
	h1, h2, h3, h4, h5 {
		color: $secondary;
		strong {
			color: $secondary;
		}
	}
	blockquote {
		border-left-color: $secondary;
	}
	blockquote > p {
		color: $secondary;
	}
	blockquote > ul {
		color: $secondary;
	}
}

// Media category buttons - not used now but will be used in the future

.media-category-button {
  text-decoration: none !important;
  color: #323232;
  margin-right: 2.5rem;
  letter-spacing: 0.5px;
}

.media-category-button:hover, .media-category-button.selected {
  color: $secondary;
  border-bottom: 2px solid $secondary;
}

// CSS to be refactored
.content img.digital-transformation-icon {
  height: 80px;
  width: 80px;
  margin-left: 0;
}

.news-content {
  position: relative;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 2rem;
  margin-top: -5rem;
  background-color: white;
}

.tag {
  display: block;
}

#business {
  display: none;
}
#government {
  display: none;
}

.image-card:hover {
  .news-content {
    background-color: darken(#ffffff, 10%);
  }
}

.hero-dropdown {
  display: flex;
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.is-centered {
  margin: auto;
}

.is-three-fifths-mobile {
  width: 50%;
  @include touch() {
    width: 100%;
  }
}